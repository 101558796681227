<template>
  <div>
    <v-simple-table fixed-header height="400px">
      <thead>
        <tr>
          <th>Reg</th>
          <th>Dept</th>
          <th>Order</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vehicle, index) in vehicles" :key="index">
          <td>{{ vehicle.reg }}</td>
          <td>
            <select class="normal-select" v-model="vehicle.department">
              <option value="0">None set</option>
              <option value="1">Thermo</option>
              <option value="2">Paint</option>
              <option value="3">Highways</option>
              <option value="4">Surveys</option>
            </select>
          </td>
          <td>
            <input
              type="number"
              min="0"
              v-model="vehicle.display_order" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="pa-2">
      <v-btn @click="saveChanges">Save changes</v-btn>
    </div>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'OrderVehicles',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      message: '',
      showMessage: false,
      vehicles: [
        {
          reg: '',
          dept: '',
          order: 0,
        },
      ],
    };
  },
  methods: {
    getVehicles() {
      axios.get(`/vehicles/fetchAllByDept.json?token=${this.token}`)
        .then((response) => {
          this.vehicles = response.data.vehicles;
        });
    },
    saveChanges() {
      const postData = {};
      postData.vehicles = this.vehicles;
      axios.post(`/vehicles/saveVehicleOrder.json?token=${this.token}`, postData)
        .then((response) => {
          this.vehicles = response.data.vehicles;
          this.message = 'Your changes have been saved';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.getVehicles();
  },
};
</script>
